/* Troop Types */

/* div {
  white-space: pre-line;
} */

div {
  display: block;
}

.troop-square {
  display: flex;
  justify-content: center;
  align-items: center;
}

.troop-image {
  width: 55%;
  height: 55%;
  object-fit: contain !important;
}

.troop-info {
  position: absolute;
  background-color: white;
  border: #faf3d9 solid 5px;
  border-radius: 10%;
  box-sizing: content-box;
  height: fit-content;
  width: 13vw;
  text-align: left;
  padding: 15px;
  z-index: 200;
  display: none;
  bottom: -25vh;
  left: 5vw;
}

.troop-square:hover .troop-info {
  display: block;
}

.bold {
  font-weight: bold;
}
/* 
.circle {
  width: 70%;
  height: 70%;
  border-radius: 50%;
  border: 1px solid black;
  box-shadow: none;
}

.b-KNIGHT {
  background-color: blue;
}

.r-KNIGHT {
  background-color: red;
} */
